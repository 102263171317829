import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import partExampleImg from '../assets/images/240.png';

const PartItemsStyles = styled.ul`
  display: flex;
  width: calc(100% + 30px);
  flex-wrap: wrap;
  justify-content: flex-start;
  li {
    width: calc(33.333333% - 30px);
    margin: 30px 30px 0 0;
    :nth-child(1),
    :nth-child(2),
    :nth-child(3) {
      margin-top: 0;
    }
    :nth-child(3n) {
      margin-right: 0px;
    }
  }
  figure {
    display: block;
    margin: 0;
    padding: 20px;
    border: 1px solid var(--black);
    border-bottom: 0;
    img {
      display: block;
      width: 100%;
    }
  }
  figcaption {
    padding: 30px;
    background: var(--black);
    transition: background 0.2s ease-in-out;
  }
  a:hover {
    figcaption {
      background: var(--green);
    }
  }
  h4 {
    color: var(--white);
    text-align: center;
  }
  @media (max-width: 767px) {
    width: 100%;
    figure {
      padding: 10px;
    }
    figcaption {
      padding: 20px 10px;
    }
    li {
      width: calc(50% - 10px);
      margin-top: 20px !important;
      :nth-child(1),
      :nth-child(2) {
        margin-top: 0 !important;
      }
      margin-right: 0;
      :nth-child(odd) {
        margin-right: 20px;
      }
    }
  }
  @media (max-width: 499px) {
    li {
      width: 100%;
      :nth-child(2) {
        margin-top: 20px !important;
      }
      margin-top: 20px !important;
      margin-right: 0 !important;
      :first-child {
        margin-top: 0 !important;
      }
    }
  }
`;

export default function PartItems({ parts }) {
  console.info(parts);
  return (
    <PartItemsStyles>
      {parts.map((item) => (
        <li key={item.id}>
          <figure>
            <img
              src={
                item.image != null ? item.image.asset.fluid.src : partExampleImg
              }
              alt={item.name}
            />
          </figure>
          <figcaption>
            <h4>{item.name}</h4>
          </figcaption>
        </li>
      ))}
    </PartItemsStyles>
  );
}
