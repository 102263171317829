import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SubPageHeader from '../components/SubPageHeader';
import PartItems from '../components/PartItems';

export default function PartsListPage({ data, pageContext }) {
  const parts = data.parts.nodes;
  const breadcrumbs = [
    {
      title: 'Parts',
      url: '/parts',
    },
  ];
  return (
    <Layout>
      <SubPageHeader title={pageContext.name} breadcrumbs={breadcrumbs} />
      <PartItems parts={parts} />
    </Layout>
  );
}

export const query = graphql`
  query PartsListQuery($url: String) {
    parts: allSanityPart(
      filter: { family: { url: { current: { eq: $url } } } }
    ) {
      nodes {
        id
        name
        url {
          current
        }
        family {
          url {
            current
          }
        }
        image {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
